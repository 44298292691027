import 'select2'
import { createSelect2, selectedValueForSelect2 } from '../src/select2_builder'
import OnloadHandler from '../src/onload_handler.js'




const countries_path = '/countries.json'
const provinces_path = '/provinces.json'
const selected_provinces_path = '/provinces/search.json'
const cities_path = '/cities.json'
const selected_cities_path = '/cities/search.json'
const zip_codes_path = '/zip_codes.json'
const selected_zip_codes_path = '/zip_codes/search.json'

const countriesBuilder = () => {
  $('[data-select2-country]').each((index, elm) => {    
    createSelect2($(elm), countries_path, 'Stato', (params) => {
      return {
        q: {
          name_ft_bool: params.term,
          s: 'name asc'
        }
      }}
    );
  });
}

const citiesBuilder = () => {
  $('[data-select2-city]').each((index, elm) => {
    const $elm = $(elm);
    createSelect2($elm, cities_path, 'Città', (params) => {
      return {
        q: {
          name_ft_bool: params.term,
          s: 'name asc'
        }
      }}
    );

    const selectedValue = elm.dataset['select2CityValue'];
    selectedValueForSelect2($elm, selectedValue, selected_cities_path);
  });
}

const provinceBuilder = () => {
  $('[data-select2-province]').each((index, elm) => {
    const $elm = $(elm);
    createSelect2($elm, provinces_path, 'Provincia', (params) => {
      return {
        q: {
          name_ft_bool: params.term,
          s: 'name asc'
        }
      }}
    );
    const selectedValue = elm.dataset['select2ProvinceValue'];
    selectedValueForSelect2($elm, selectedValue, selected_provinces_path);
  });
}

const zipcodesBuilder = () => {
  $('[data-select2-zipcode]').each((index, elm) => { 
    const $elm = $(elm);
    createSelect2($elm, zip_codes_path, 'Cap', (params) => {
      const query = {
        code_ft_bool: params.term,
        s: 'code asc'
      };

      const cityConnected = elm.dataset['select2Zipcode'];
      query['city_id_eq'] = $(`[name="${cityConnected}"]`).val() || '0';
      

      return {
        q: query
      };
    }, {minInputLength: 0, scrollAfterSelect: true});

    const selectedValue = elm.dataset['select2ZipcodeValue'];
    selectedValueForSelect2($elm, selectedValue, selected_zip_codes_path);
  });
}


OnloadHandler({
  onload_cb: () => {
    countriesBuilder();
    provinceBuilder();
    citiesBuilder();
    zipcodesBuilder();
  },
  before_cache_cb: () => {
    $('[data-select2-country]').each((index, elm) => $(elm).select2('destroy'));
    $('[data-select2-province]').each((index, elm) => $(elm).select2('destroy'));
    $('[data-select2-city]').each((index, elm) => $(elm).select2('destroy'));
    $('[data-select2-zipcode]').each((index, elm) => $(elm).select2('destroy'));
  }
});