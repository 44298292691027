// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import "controllers"

import 'bootstrap'

import 'select2'
import 'select2/src/scss/core.scss'
import 'select2/dist/js/i18n/it'
import '@ttskch/select2-bootstrap4-theme/dist/select2-bootstrap4.min.css'
import "@fortawesome/fontawesome-free/css/all"

import '../stylesheets/application/application.scss'

import '../src/address_autocomplete.js.erb'

import '../src'


Rails.start()
Turbolinks.start()

const images = require.context('../images', true)
const imagePath = (name) => images(name, true)


