import OnloadHandler from '../src/onload_handler.js'

const previewVideo = () => {

  const input = document.querySelector('[data-file]');
  const inputFileName = document.querySelector('[data-file-name]');
  const inputFileError = document.querySelector('[data-file-error]');
  const inputFileMaxsize = document.querySelector('[data-file-maxsize]');
  const video = document.getElementById('video_preview');

  if (!input) {
    return;
  }

  input.addEventListener('change', (event) => {
    const files = event.target.files || [];

    if (!files.length) return;

    const file = files[0];
    const blobURL = URL.createObjectURL(file);

    if (video) {
      video.src = blobURL;
    }
    if (inputFileName) {
      inputFileName.textContent = `${file.name} ${fileSize(file.size)}`;
    }
    let maxSize = 20000000
    if (inputFileMaxsize) {
      maxSize = +inputFileMaxsize;
    }
    if (file.size > maxSize) {
      input.setCustomValidity(`Dimensione massima consentita: ${fileSize(maxSize)}`);
      inputFileError.classList.add('d-block');
    } else {
      input.setCustomValidity('');
      inputFileError.classList.remove('d-block');
    }
  });
}

const fileSize = (number) => {
  if (number < 1024) {
    return number + ' bytes';
  } else if (number >= 1024 && number < 1048576) {
    return (number / 1024).toFixed(1) + ' KB';
  } else if (number >= 1048576) {
    return (number / 1048576).toFixed(1) + ' MB';
  }
}

OnloadHandler({
  onload_cb: () => {
    previewVideo()
  }
});
