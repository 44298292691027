export const createSelect2 = (
  $elm,
  url,
  placeholder,
  buildParamFn,
  options = {
    delay: 250,
    minInputLength: 2,
    withClear: true,
    scrollAfterSelect: false
  }
) => {
  if (!$.fn.select2) return;

  $elm.select2({
    theme: 'bootstrap4',
    width: 'style',
    placeholder: placeholder,
    allowClear: options.withClear,
    minimumInputLength: options.minInputLength,
    ajax: {
      delay: options.delay,
      url: url,
      dataType: 'json',
      data: buildParamFn,
      scrollAfterSelect: options.scrollAfterSelect
    }
  });
}

export const createSelect2Tags = (
  $elm,
  url,
  placeholder,
  buildParamFn,
  createTagFn,
  options = { delay: 250, minInputLength: 2, withClear: false, scrollAfterSelect: false }
) => {
  if (!$.fn.select2) return;

  $elm.select2({
    theme: 'bootstrap4',
    width: 'style',
    placeholder: placeholder,
    allowClear: options.withClear,
    minimumInputLength: options.minInputLength,
    tags: true,
    createTag: createTagFn,
    ajax: {
      delay: options.delay,
      url: url,
      dataType: 'json',
      data: buildParamFn,
      scrollAfterSelect: options.scrollAfterSelect
    }
  });
}

export const selectedValueForSelect2 = (
  $elm,
  val,
  url,
) => {
  if (val) {
    $.ajax({
        method: 'get',
        url: url,
        data: {id: val}
    }).done(function(response) {
        if (response.results.length == 0) return;

        var option = new Option(response.results[0].text, response.results[0].id, true, true);
        $elm.append(option).trigger('change');

        $elm.trigger({
            type: 'select2:select',
            params: {
                data: response.results
            }
        });
    }).fail(function(data) {
        console.log('error on pre-selected city', data);
    });
  }
}