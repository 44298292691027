
import { Controller } from 'stimulus'
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ['audio', 'spinner']
  audioLoaded = false;
  connect() {
    this.makeRequest()
    this.interval = setInterval(() => {
      this.makeRequest()
    }, 5000)
  }

  showAudio() {
    this.audioTarget.src = this.element.dataset.audioUrl;
    this.audioTarget.classList.remove('d-none');
    this.audioTarget.onerror = () => {
      this.showError();
    }
    this.spinnerTarget.classList.add('d-none');

    if(this.element.dataset.audioLogUrl) {
      this.audioTarget.onplay = () => {
        Rails.ajax({
          type: 'get',
          url: this.element.dataset.audioLogUrl,
          error: (response, statusText) => {
            console.log('Si è verificato un errore durante il log di visualizzazone audio');
          }
        })
      }
    }
  }

  makeRequest() {
    Rails.ajax({
      type: 'get',
      url: this.element.dataset.audioCheckerUrl,
      success: (response) => {
        if (!this.audioLoaded && response.status == "ok"){
          this.audioLoaded = true;
          clearInterval(this.interval);

          setTimeout(() => {
            this.showAudio()
          }, 5000)
        }
      }, error: (response, statusText) => {
        alert('Si è verificato un errore durante il recupero del audio');
        clearInterval(this.interval);
      }
    })
  }

  showError() {
    const htmlError = `
    <div data-header-notification>
      <div class="alert alert-danger alert-dismissible fade show" role="alert">
        <button class="close" type="button" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        Si è verificato un errore durante il caricamento del file audio. Prova a ricaricare la pagina.
      </div>
    </div>`;
    document.querySelector('[data-header-notification]').outerHTML = htmlError;
  }

  disconnect() {
    clearInterval(this.interval)
  }
}