// import stimulus-flatpickr wrapper controller to extend it
import Flatpickr from "stimulus-flatpickr";

// you can also import a translation file
import { Italian } from "flatpickr/dist/l10n/it.js";
import { yearDropdownPlugin } from "../src/year_dropdown_plugin";

// import a theme (could be in your main CSS entry too...)
import "flatpickr/dist/themes/light.css";
import dateFormat from "dateformat";

// create a new Stimulus controller by extending stimulus-flatpickr wrapper controller
export default class extends Flatpickr {
  initialize() {
    // sets your language (you can also set some global setting for all time pickers)
    this.config = {
      locale: Italian,
      altInput: true,
      allowInput: true,
      plugins: [
        new yearDropdownPlugin({
          date:
            this.data.element.value && this.data.element.value.indexOf("-") > -1
              ? this.data.element.value
              : dateFormat(new Date(), "yyyy-mm-dd"),
          yearStart: +(this.element.dataset.flatpickerYearStart || 0),
          yearEnd: +(this.element.dataset.flatpickerYearEnd || 5)
        }),
      ],
    };
    this.validDates = null;
  }

  yearChange(selectedDates, dateStr, instance) {
    instance.yearSelectContainer.children[0].value = instance.currentYear;
  }
}