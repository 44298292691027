import Rails from "@rails/ujs"
import { Controller } from 'stimulus';

export default class extends Controller {

  static targets = ['form'];

  connect() {
    this.formTarget.onsubmit = (event) => this.submitForm(event);
  }

  submitForm(event) {
    let isValid = this.validateForm(this.formTarget);

    // If our form is invalid, prevent default on the event
    // so that the form is not submitted
    if (!isValid) {
      event.preventDefault();
      document.querySelectorAll('input[type=submit]').forEach((elm) => {
        delete elm.dataset.disableWith;
        elm.disabled = false;
      });
    }
  }

  validateForm() {
    let isValid = true;

    let requiredFieldSelectors = 'select:required, textarea:required, input:required';
    let requiredFields = this.formTarget.querySelectorAll(requiredFieldSelectors);

    requiredFields.forEach((field) => {
      if (!field.disabled && ((field.type != 'checkbox' && !field.value.trim()) || (field.type == 'checkbox' && !field.checked))) {
        field.focus();
        field.classList.add('is-invalid');

        isValid = false;

        return false;
      } else {
        field.classList.remove('is-invalid');
      }
    });
    
    // If we already know we're invalid, just return false
    if (!isValid) {
      return false;
    }

    // Search for any browser invalidated input fields and focus them
    let invalidFields = this.formTarget.querySelectorAll('input:invalid');
    
    invalidFields.forEach((field) => {
      if (!field.disabled) {
        field.focus();
        field.classList.add('is-invalid');
        
        isValid = false;
      }
    });
    
    return isValid;
  }
}