import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['readonlyContainer', 'editContainer']

  connect() {
    this.showReadonly()
  }

  showEdit() {
    this.hide(this.readonlyContainerTarget);
    this.show(this.editContainerTarget);
  }

  showReadonly() {
    this.hide(this.editContainerTarget);
    this.show(this.readonlyContainerTarget);
  }

  show(target) {
    target.classList.remove('d-none')
  }

  hide(target) {
    target.classList.add('d-none')
  }
}