/***
 * options properties
 * 
 * onload_cb: function for turbolinks:load
 * before_visit_cb: function for turbolinks:before-visit
 * visit_cb: function for turbolinks:visit
 * before_cache_cb: function for turbolinks:before-cache
 */
 const OnloadHandler = ({ onload_cb, before_visit_cb, visit_cb, before_cache_cb }) => {

  document.addEventListener("turbolinks:load", () => {
    typeof onload_cb === "function" && onload_cb();
  });
  document.addEventListener("turbolinks:before-visit", () => {
    typeof before_visit_cb === "function" && before_visit_cb();
  });
  document.addEventListener("turbolinks:visit", () => {
    typeof visit_cb === "function" && visit_cb();
  });
  document.addEventListener("turbolinks:before-cache", () => {
    typeof before_cache_cb === "function" && before_cache_cb();
  })
};

export default OnloadHandler;
