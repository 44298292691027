
import { Controller } from 'stimulus'
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ['name', 'surname', 'alias', 'isCaregiver']

  connect() {
    if(this.hasIsCaregiverTarget) {
      this.nameTarget.onchange = () => this.setAlias();
      this.surnameTarget.onchange = () => this.setAlias();
      this.isCaregiverTarget.onchange = () => {
        if(this.isCaregiverTarget.checked) {
          this.aliasTarget.value = '';
        }
      }
    }
  }

  setAlias() {
    if(!this.isCaregiverTarget.checked) {
      this.aliasTarget.value = `${this.nameTarget.value} ${this.surnameTarget.value}`;
    }
  }

  disconnect() {
    this.nameTarget.onchange = null;
    this.surnameTarget.onchange = null;
  }
}