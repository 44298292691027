import OnloadHandler from '../src/onload_handler.js'
import emailScramble from 'email-scramble';

OnloadHandler({
  onload_cb: () => {
    window.emailScramble = emailScramble;

    var links = document.querySelectorAll('a[data-email-s]');

    links.forEach(function decodeLink(link) {
      var l = link;
      link.addEventListener("click", function (event) {
        if (!l.getAttribute('data-email-s-decoded')) {
          var href = l.href;
          if (href.indexOf('%') >= 0) {
            href = decodeURI(href);
          }
          l.href = emailScramble.decode(href);
          l.setAttribute('data-email-s-decoded', true);
        }
      });
    });

    var spans = document.querySelectorAll('span[data-email-s]');

    spans.forEach(function decodeLink(span) {
      span.innerHTML = emailScramble.decode(span.innerHTML);
    });
  }
});