
import { Controller } from 'stimulus'
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ['export', 'spinner']
  exportLoaded = false;
  
  connect() {
    this.makeRequest()
    this.interval = setInterval(() => {
      this.makeRequest()
    }, 5000)
  }

  showDownload() {
    this.exportTarget.classList.remove('d-none');
    this.spinnerTarget.classList.add('d-none');    
  }

  makeRequest() {
    Rails.ajax({
      type: 'get',
      url: this.element.dataset.exportCheckerUrl,
      success: (response) => {
        if (!this.exportLoaded && response.status == "ok"){
          this.exportLoaded = true;
          clearInterval(this.interval);

          setTimeout(() => {
            this.showDownload()
          }, 5000)
        }
      }, error: (response, statusText) => {
        alert('Si è verificato un errore durante il il recupero del file');
        clearInterval(this.interval);
      }
    })
  }

  disconnect() {
    clearInterval(this.interval)
  }
}