import OnloadHandler from '../src/onload_handler.js'

function selectedOptionEmpty() {
  if (!this.options[this.selectedIndex].value) {
    this.classList.add('empty');
  } else {
    this.classList.remove('empty');
  }
}

function copyToClipboard(elm) {
  const copyText = document.querySelector(elm).textContent;
  const el = document.createElement('textarea');
  el.value = copyText;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
}

OnloadHandler({
  onload_cb: () => {
    $('[data-toggle="tooltip"]').tooltip()

    document.querySelectorAll('[data-empty-option-colored]').forEach((elm) => {
      elm.onchange = selectedOptionEmpty;
      selectedOptionEmpty.bind(elm)();
    });

    document.querySelectorAll('[data-copy]').forEach((elm) => {
      elm.onclick = () => copyToClipboard(elm.dataset['copy'])
    });

    $('[data-accordion-animate]').on('shown.bs.collapse', (e) => {
      const offset = $(e.currentTarget).parents('[data-accordion-header]').offset();
      window.scrollTo(0, offset.top);
    });
  }
});

document.addEventListener("DOMContentLoaded", () => {
  if (window.document.documentMode) {
    let div = document.createElement('div');
    let message = document.createElement('h1');

    message.innerHTML = 'Attenzione! Internet Explorer non è supportato.'

    div.style.cssText = 'background-color:grey;width: 100%;height: 100%;position: fixed;z-index:99999;top: 0;text-aling:center';
    message.style.cssText = 'color: white;text-align: center;top: 45%;position: relative;';

    document.body.appendChild(div);
    div.appendChild(message);
  }
});